import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ParallaxSection from "../components/parallaxSection"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="Big Ragoo"
      heroSubTitle="Post-Punk Carnival-Rock"
      heroBackgroundImage="/images/big-ragoo-raising-teacher-class.jpg"
    >
      <SEO
        title="Big Ragoo | Post-Punk Carnival-Rock"
        ogDescription="Big Ragoo is a post-punk carnival-rock band from Atlanta now splitting time between New York and Georgia."
        image="https://bigraoo.com/images/big-ragoo-raising-teacher-class.jpg"
        url="https://bigraoo.com"
      />
      <PageWrapper color="purple">
        <SingleColumnBlock
          textPadded
          centered
          text={
            <div>
              <p>
                In 1996, The Big Ragoo was formed as a side project by three members of the Atlanta post-punk band Monkey Boy. Jimmy Ether, Ken Whitener and Shawn Christopher -- all prolific songwriters -- would rotate instruments with the principal songwriter playing guitar. Lyrics were written communally based on what each member thought the others were singing, resulting in both interesting motifs and complete absurdity. Musically, the trio pulled heavily from the influence of Trumans Water, fIREHOSE, Soul-Junk, Sebadoh and Archers of Loaf.
              </p>
              <p>
                In 2010, the group refromed working remotely between Atlanta, GA and Ithaca, NY. This resulted in the Raising LP.
              </p>
              <p>
                Though progress is slow, other records are in the works. More to come.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        centered
        text={
          <div>
            <h2>Reformation</h2>
            <p></p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/big-ragoo-raising-lp.jpg" alt="Raising by Big Ragoo" />
            <p className="small">Illustrations by Doc Woglam.</p>
          </div>
        }
        textRight={
          <div>
            <h3>Raising (LP)</h3>
            <p>
              Written and recorded remotely between Atlanta, GA and Ithica, NY. We did a lot of Zoom band meetings and were figuring out a process that worked while being nearly a thousand miles apart. The result was a swiril of guitar over a collage of hi-fi and low-fi sounds.
            </p>
            <h4>Download</h4>
            <p>
              Download in lossless or lossy archival format of your choice at <a href="https://bigragoo.bandcamp.com/" target="_blank">Bandcamp</a>.
            </p>
            <h4>Stream</h4>
            <p>
              Coming Soon!
            </p>
          </div>
        }
      />
      <ParallaxSection
        src={`/images/big-ragoo-90s-collage.jpg`}
        height={100}
      />
      <PageWrapper color="charcole">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h2>The 90s</h2>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/big-ragoo-fragrent-fowl.jpg" alt="Fragrant Fowl! by Big Ragoo" />
            </div>
          }
          textRight={
            <div>
              <h3>Fragrant Fowl!</h3>
              <p>
                In mid-1997, the band spent a weekend at King Mattress Studio in Avondale, GA to record a two-song 7". The resulting "Fragent Fowl!" release featured two re-worked songs from the previous year's record.
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://bigragoo.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Coming soon.
              </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>Wreck of the Big Ragoo</h3>
              <p>
                Recorded the weekend of August 31st, 1996 at Channel One Studio on Clifton Road, Atlanta by Jimmy Ether. Music tracked live to DAT. Originally the music was dubbed down to cassette 4-track and the vocals overdubbed (mostly live at once) to the remaining two tracks. For this reissue, the cassette vocal tracks were re-synced to the original music master, remixed and remastered. Reissue work done by Jimmy Ether September/October of 2010 at Headphone Treats.
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://bigragoo.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Coming soon.
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/wreck-of-the-big-ragoo-lp.jpg" alt="Wreck of the Big Ragoo by Big Ragoo" />
            </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/jimmy-ether-big-ragoo-1996.jpg" alt="Jimmy Ether of Big Ragoo" />
          </div>
        }
        textRight={
          <div>
            <h3>Jimmy Ether</h3>
            <h4>Bass/Guitar/Drums/Vocals</h4>
            <p>
              Jimmy at the old Sphere Eclipse A console at Channel One Studios, in Atlanta. This is where the frist LP was recorded, live to DAT with vocal overdubs to cassette 4-track.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Ken Whitener</h3>
            <h4>Drums/Bass/Guitar/Vocals</h4>
            <p>
              Ken cooling off and listening to the last few takes of a song.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/ken-whitener-big-ragoo-1996.jpg" alt="Ken Whitener of Big Ragoo" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/shawn-christopher-big-ragoo-1996.jpg" alt="Shawn Christopher of Victory Hands" />
          </div>
        }
        textRight={
          <div>
        <h3>Shawn Christopher</h3>
        <h4>Guitar/Drums/Vocals</h4>
        <p>
          Shawn running through riffs on guitar.
        </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Follow us</h2>
            <p>
              To be kept up to date on new releases follow us on <a href="https://www.facebook.com/bigragoo/" target="_blank">Facebook</a> and <a href="https://www.instagram.com/_bigragoo/" target="_blank">Instagram</a>.
            </p>
            <h2>Give us a holler</h2>
            <RequestMoreInfo />
          </div>
        }
        textRight={
          <div>
            <img src="/images/big-ragoo-raising-metal-detector-man.jpg" alt="Big Ragoo Social Media" />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
